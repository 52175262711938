import React from "react";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import Media from "react-md/lib/Media/Media";
import List from "react-md/lib/Lists/List";
import ListItem from "react-md/lib/Lists/ListItem";
import Avatar from "react-md/lib/Avatars/Avatar";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../layout'

const Contact = () => (

  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "contact.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Media>
              <Img fluid={data.Image.childImageSharp.fluid} />
            </Media>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="Contact Us" />
              <CardText>
                <List>
                  <a href="mailto:admissions@mindfulmontessori.com">
                    <ListItem
                      leftAvatar={<Avatar icon={<FontIcon>mail</FontIcon>} />}
                      primaryText="Admissions Inquiry Online and Application"
                      secondaryText="admissions@mindfulmontessori.com"
                    />
                  </a>
                  <a href="mailto:info@mindfulmontessori.com">
                    <ListItem
                      leftAvatar={<Avatar icon={<FontIcon>mail</FontIcon>} />}
                      primaryText="General Questions"
                      secondaryText="info@mindfulmontessori.com"
                    />
                  </a>
                  <a href="tel:+917702055564">
                    <ListItem
                      leftAvatar={<Avatar icon={<FontIcon>phone</FontIcon>} />}
                      primaryText="Call Us"
                      secondaryText="+91 77020 55564"
                    />
                  </a>
                </List>
              </CardText>
            </Card>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="Our Address" />
              <CardText>
                <p>H No 5-71 TNGO Colony (Behind Q-City)</p>
                <p>Gachibowli, Hyderabad, Telangana 500032</p>
              </CardText>
              <div style={{width: '75%', height: '450px', display: 'block', margin: '0 auto'}}>
                <iframe
                  title="Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d800.25056207491!2d78.33215667513701!3d17.428353541564356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1e88e5211c14a3ee!2zMTfCsDI1JzQxLjciTiA3OMKwMTknNTQuOCJF!5e0!3m2!1sen!2sus!4v1640108772243!5m2!1sen!2sus"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{border: 0}}
                  allowFullScreen
                />
              </div>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default Contact
